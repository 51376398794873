import React, { useContext, useState, useMemo, useCallback } from 'react'
import qs from 'query-string'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Spinner,
} from 'reactstrap'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import find from 'lodash/find'
import Select from 'react-select'
import keyBy from 'lodash/keyBy'
import { Link } from 'react-router-dom'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { FasiSchedulateState } from './localstate'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import Column from '../../components/Column'
import Paginator from '../../components/Paginator'
import { ConfigPlannerContext } from '../../context'
import { PERMISSIONS } from '../../django'
import MultipleMaterialiFilters from '../../components/Planner/MultipleMaterialiFilters'

const qpList = {
  encode: (a) => a,
  decode: (a) => (Array.isArray(a) ? a : [a]).filter(Boolean),
}

function printTime(strTime) {
  if (strTime === null) {
    return ''
  }
  const d = moment.duration(strTime)
  return `${d.asMinutes()} m`
  // let pieces = []
  // if (d.days() > 0) {
  //   pieces.push(`${d.days()} G`)
  // }
  // if (d.hours() > 0) {
  //   pieces.push(`${d.hours()} H`)
  // }
  // if (d.minutes() > 0) {
  //   pieces.push(`${d.minutes()} m`)
  // }
  // return pieces.join(' ')
}

const ColorBox = ({ color, className = '' }) => (
  <div
    className={`border rounded ${className}`}
    style={{ backgroundColor: color, height: 15, width: 15 }}
  />
)

const MiniRowStato = React.memo(
  ({ nomeFase, stati, statiFornitori, valoriStato }) => {
    if ((nomeFase === 'banchi' || nomeFase === 'macchine') && stati[nomeFase]) {
      const stato = stati[nomeFase].stato
      const colorsByStati = keyBy(
        valoriStato[nomeFase].filter((v) => v.color),
        'nome'
      )
      const color = colorsByStati[stato]?.color
      return (
        <tr className="bg-transparent">
          <td className="font-weight-bold">{capitalize(nomeFase)}</td>
          <td></td>
          <td></td>
          <td className="text-black-50 text-nowrap">{stato}</td>
          {color && (
            <td>
              <ColorBox color={color} />
            </td>
          )}
        </tr>
      )
    } else if (statiFornitori[nomeFase] && statiFornitori[nomeFase].length) {
      const colorsByStati = keyBy(
        valoriStato['ordine_fornitore'].filter((v) => v.color),
        'nome'
      )
      const ordiniFornitoriLoop = statiFornitori[nomeFase]
      return (
        <>
          {ordiniFornitoriLoop.map((ordineFornitore) => (
            <tr className="bg-transparent" key={ordineFornitore.id}>
              <td className="font-weight-bold">{capitalize(nomeFase)}</td>
              <td className="text-nowrap">{ordineFornitore.fornitore.nome}</td>
              <td>
                {ordineFornitore.data_consegna
                  ? moment(ordineFornitore.data_consegna).format('DD/MM/YYYY')
                  : '-'}
              </td>
              <td className="text-black-50 text-nowrap">
                {ordineFornitore.stato}
              </td>
              <td>
                {colorsByStati[ordineFornitore.stato] && (
                  <ColorBox
                    color={colorsByStati[ordineFornitore.stato].color}
                  />
                )}
              </td>
            </tr>
          ))}
        </>
      )
    }
    return null
  }
)

const MiniTableStati = React.memo(({ fase }) => {
  const { fasiScheduler, valoriStato } = useContext(ConfigPlannerContext)

  return (
    <table
      style={{ fontSize: 14 }}
      className="m-0 p-0 table table-borderless table-sm"
    >
      <tbody>
        {fasiScheduler.map((faseConf) => (
          <MiniRowStato
            key={faseConf.nome_fase}
            nomeFase={faseConf.nome_fase}
            valoriStato={valoriStato}
            stati={fase.stati}
            statiFornitori={fase.stati_ordini_fornitori}
          />
        ))}
      </tbody>
    </table>
  )
})

function TableFasiSchedulate({
  fasiSchedulate,
  onStatoClicked,
  faseConf,
  totals,
  filters,
  onFilterChange,
  loading,
}) {
  const hasFornitore = get(faseConf, 'with_fornitore', false)
  const showDurata = !get(faseConf, 'infinite_capacity', false)
  const nomeFase = get(faseConf, 'nome_fase')

  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, 'ordering'),
  }

  return (
    <div className="table-responsive pt-2">
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <td className="text-center">
              {totals && (
                <span className="badge badge-primary">
                  {totals.totale_numero_serramenti}
                </span>
              )}
            </td>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>
              {totals && (
                <span className="badge badge-primary">
                  {totals.totale_ante}
                </span>
              )}
            </th>
            <th>
              {totals && (
                <span className="badge badge-primary">
                  {totals.totale_pezzi_ante}
                </span>
              )}
            </th>
            {hasFornitore && <th></th>}
            {showDurata && (
              <td className="text-center">
                {totals && (
                  <span className="badge badge-primary">
                    {printTime(totals.totale_durata)}
                  </span>
                )}
              </td>
            )}
            <th></th>
            <th></th>
            <th></th>
            <th>
              {totals && (
                <span className="badge badge-primary">{totals.tot_netto}</span>
              )}
            </th>
          </tr>
          <tr>
            <th>Numero commessa</th>
            <th>Riferimento</th>
            <Column {...columnProps} field="numero_serramenti">
              Numero serramenti
            </Column>
            <th>Tipi serramenti</th>
            <th>Stati</th>
            <th>Stato</th>
            <Column
              {...columnProps}
              field="schedulazione_ordine__ordine__cliente__nome"
            >
              Cliente
            </Column>
            <Column {...columnProps} field="numero_ante">
              Ante
            </Column>
            <Column {...columnProps} field="numero_pezzi_ante">
              N° Pezzi Ante
            </Column>
            {hasFornitore && <th>Fornitore</th>}
            {showDurata && (
              <Column {...columnProps} field="computed_durata">
                Tempo
              </Column>
            )}
            <Column
              {...columnProps}
              field="schedulazione_ordine__ordine__data_consegna"
            >
              Data approntamento
            </Column>
            <Column {...columnProps} field="inizio">
              Inizio
            </Column>
            <Column {...columnProps} field="fine">
              {faseConf && faseConf.nome_fase === 'verniciatura'
                ? 'Consegna in QR'
                : 'Fine'}
            </Column>
            <th>Netto</th>
          </tr>
        </thead>
        {fasiSchedulate === null || loading ? (
          <div className="text-center mt-4">
            <Spinner color="primary" />
          </div>
        ) : (
          <tbody>
            {fasiSchedulate &&
              fasiSchedulate.map((fase) => (
                <tr key={fase.id}>
                  <td>
                    <div className="d-flex justify-content-between">
                      {PERMISSIONS.viewOrders ? (
                        <Link
                          to={`/ordini/${fase.schedulazione_ordine.ordine.id}`}
                        >
                          {fase.schedulazione_ordine.ordine.numero_commessa}
                        </Link>
                      ) : (
                        <span>
                          {fase.schedulazione_ordine.ordine.numero_commessa}
                        </span>
                      )}
                      {PERMISSIONS.scheduleOrder && (
                        <div>
                          <Link
                            to={`/schedulatore?id=${fase.schedulazione_ordine.ordine.id}`}
                          >
                            <i className="fas fa-clock text-success"></i>
                          </Link>
                        </div>
                      )}
                    </div>
                  </td>
                  <td>{fase.schedulazione_ordine.ordine.riferimento}</td>
                  <td className="text-center">
                    {fase.schedulazione_ordine.ordine.numero_serramenti}
                  </td>
                  <td>{fase.schedulazione_ordine.ordine.tipi_serramenti}</td>
                  <td>
                    <MiniTableStati fase={fase} />
                  </td>
                  <td>
                    {nomeFase === 'banchi' || nomeFase === 'macchine' ? (
                      <>
                        {get(fase, 'stato.stato', null)}
                        <Button
                          onClick={() => onStatoClicked(fase)}
                          color="success"
                          outline
                          size="sm"
                          className="ml-1"
                        >
                          <i
                            className={`fas ${
                              !fase.stato ? 'fa-plus-circle' : 'fa-edit'
                            }`}
                          ></i>
                        </Button>
                      </>
                    ) : (
                      (fase.stati_ordini_fornitori[nomeFase] || [])
                        .map((o) => o.stato)
                        .join(', ')
                    )}
                  </td>
                  <td>{fase.schedulazione_ordine.ordine.cliente.nome}</td>
                  <td className="text-center">
                    {fase.schedulazione_ordine.ordine.numero_ante}
                  </td>
                  <td className="text-center">
                    {fase.schedulazione_ordine.ordine.numero_pezzi_ante}
                  </td>
                  {hasFornitore && <td>{get(fase, 'fornitore.nome', null)}</td>}
                  {showDurata && (
                    <td className="text-nowrap">
                      {printTime(fase.durata_override || fase.durata)}
                    </td>
                  )}
                  <td>
                    {fase.schedulazione_ordine.ordine.data_consegna
                      ? moment(
                          fase.schedulazione_ordine.ordine.data_consegna
                        ).format('DD/MM/YYYY')
                      : null}
                  </td>
                  <td>
                    {fase.inizio
                      ? moment(fase.inizio).format('DD/MM/YYYY HH:mm')
                      : null}
                  </td>
                  <td>
                    {fase.fine
                      ? moment(fase.fine).format('DD/MM/YYYY HH:mm')
                      : null}
                  </td>
                  <td>{fase.schedulazione_ordine.ordine.netto}</td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  )
}

const FORMAT = 'YYYY-MM-DD'

function parseDate(rawDate) {
  if (rawDate === null || rawDate === '') {
    return null
  } else {
    const m = moment(rawDate, FORMAT)
    return m.isValid() ? m : null
  }
}

function FasiFilters({ filters, onFilterChange, onFiltersChange, loading }) {
  const { fasiScheduler, valoriStato } = useContext(ConfigPlannerContext)
  const [focusedInput, setFocusedInput] = useState(null)

  const { from_date, to_date } = filters
  const [startDate, endDate] = useMemo(
    () => [parseDate(from_date), parseDate(to_date)],
    [from_date, to_date]
  )

  const valoriForFase =
    (filters.nome_fase
      ? filters.nome_fase === 'macchine' || filters.nome_fase === 'banchi'
        ? valoriStato[filters.nome_fase]
        : valoriStato.ordine_fornitore
      : EMPTY_LIST) || EMPTY_LIST

  const valoriStatoToUse = useMemo(() => {
    return valoriForFase
      .map((c) => ({
        value: c.nome,
        label: c.nome,
      }))
      .concat([
        {
          value: 'NESSUNO STATO',
          label: 'NESSUNO STATO',
        },
      ])
  }, [valoriForFase])

  const selectedStati = useMemo(() => {
    return filters.stato.map((stato) => ({
      label: stato,
      value: stato,
    }))
  }, [filters.stato])

  const linkCsv = useMemo(() => {
    const stato = get(filters, 'stato', [])
    let fixFilters = { ...filters }
    if (stato && stato.includes('NESSUNO STATO')) {
      fixFilters = {
        ...fixFilters,
        stato: filters.stato.filter((x) => x !== 'NESSUNO STATO'),
        empty: true,
      }
      return `/api/planner/fasi-schedulate/csv/?${qs.stringify(fixFilters)}`
    }
    return `/api/planner/fasi-schedulate/csv/?${qs.stringify(filters)}`
  }, [filters])

  const materialiOptions = useMemo(
    () => fasiScheduler.filter((f) => f.with_fornitore).map((f) => f.nome_fase),
    [fasiScheduler]
  )

  const valoriStatoOptionsMateriali = useMemo(() => {
    return valoriStato.ordine_fornitore.map((c) => ({
      value: c.nome,
      label: c.nome,
    }))
  }, [valoriStato])

  return (
    <div>
      <div className="row">
        <div className="col-md-2 mb-2 mb-md-0">
          <select
            className="form-control"
            onChange={(e) =>
              onFiltersChange({ nome_fase: e.target.value, stato: '' })
            }
            name="nome_fase"
            value={filters.nome_fase}
          >
            {fasiScheduler.map((fase) => (
              <option key={fase.nome_fase} value={fase.nome_fase}>
                {capitalize(fase.nome_fase)}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-5 mb-2 mb-md-0">
          <Select
            name="stati"
            isMulti
            placeholder={'Seleziona uno o più stati'}
            options={valoriStatoToUse}
            closeMenuOnSelect={true}
            isDisabled={loading}
            value={selectedStati}
            className="basic-multi-select select-multi-options text-nowrap w-100"
            onChange={(newValues) => {
              if (newValues) {
                const items = newValues.map((item) => item.value)
                onFilterChange(items, 'stato')
              } else {
                onFilterChange([], 'stato')
              }
            }}
          />
        </div>
        <div
          className="col-md-3 d-flex flex-column justify-content-end"
          style={{ zIndex: 9 }}
        >
          <DateRangePicker
            startDate={startDate}
            startDateId="from_date"
            endDate={endDate}
            block={true}
            showClearDates={true}
            horizontalMargin={30}
            endDateId="to_date"
            startDatePlaceholderText={'Data inizio'}
            endDatePlaceholderText={'Data fine'}
            hideKeyboardShortcutsPanel
            isOutsideRange={() => false}
            onDatesChange={({ startDate, endDate }) => {
              onFiltersChange({
                from_date: startDate ? startDate.format(FORMAT) : '',
                to_date: endDate ? endDate.format(FORMAT) : '',
              })
            }}
            focusedInput={focusedInput}
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
          />
        </div>
        <div className="col-md-2 mt-2">
          <Button
            tag="a"
            href={linkCsv}
            size="sm"
            color="primary"
            disabled={fasiScheduler && fasiScheduler.length === 0}
          >
            <i className="fa fa-file" /> Esporta
          </Button>
        </div>
      </div>
      <MultipleMaterialiFilters
        makeLabelMateriale={capitalize}
        placeholderMateriale='-- Materiali --'
        filters={filters}
        valoriStatoOptions={valoriStatoOptionsMateriali}
        onFiltersChange={onFiltersChange}
        materialiOptions={materialiOptions}
      />
    </div>
  )
}

const START_OF_WEEK = moment().startOf('week').format('YYYY-MM-DD')
const END_OF_WEEK = moment().endOf('week').format('YYYY-MM-DD')

const EMPTY_LIST = []
function ModalContentStato({ fase, valoriStato, toggle, onSave, saving }) {
  const valoriForFase =
    (fase ? valoriStato[fase.nome_fase] : EMPTY_LIST) || EMPTY_LIST
  const [selectedStato, setSelectedStato] = useState(
    get(fase, 'stato.stato', '')
  )

  return (
    <>
      <ModalHeader toggle={toggle}>
        {fase &&
          (fase.stato
            ? `Modifica stato ordine ${fase.schedulazione_ordine.ordine.numero_commessa} fase ${fase.nome_fase}`
            : `Aggiunti stato ordine ${fase.schedulazione_ordine.ordine.numero_commessa} fase ${fase.nome_fase}`)}
      </ModalHeader>
      <ModalBody>
        {fase && (
          <div>
            <div className="form-group">
              <label>Seleziona Stato</label>
              <select
                className="form-control"
                onChange={(e) => setSelectedStato(e.target.value)}
                value={selectedStato}
              >
                <option value=""></option>
                {valoriForFase.map((valore) => (
                  <option key={valore.id} value={valore.nome}>
                    {valore.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button onClick={toggle}>Annulla</Button>
        <Button
          onClick={() => onSave(fase, selectedStato)}
          disabled={!selectedStato || saving}
          color="success"
        >
          Salva
        </Button>
      </ModalFooter>
    </>
  )
}

export default function FasiSchedulate() {
  const [
    // Data State
    { fasiSchedulate, pagination, savingStato, totals, loading },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFilterChange,
      onFiltersChange,
      goToPage,
      // Rj Actions
      setStato,
    },
  ] = useDataTable(FasiSchedulateState, () => ({
    nome_fase: 'banchi',
    // stato: "",
    from_date: START_OF_WEEK,
    to_date: END_OF_WEEK,
    ordering: '',
    stato: {
      value: [],
      encdec: qpList,
    },
    materiali: {
      value: [],
      encdec: qpList,
    },
    stati_materiali: {
      value: [],
      encdec: qpList,
    },
  }))
  const [showModalStato, setShowModalStato] = useState(null)
  const toggleModalStato = useCallback(() => setShowModalStato(null), [])
  const { valoriStato, fasiScheduler } = useContext(ConfigPlannerContext)

  const faseConf = useMemo(
    () => find(fasiScheduler, { nome_fase: filters.nome_fase }),
    [fasiScheduler, filters.nome_fase]
  )

  return (
    <div className="container-fluid mt-1">
      <FasiFilters
        filters={filters}
        onFilterChange={onFilterChange}
        onFiltersChange={onFiltersChange}
        loading={loading}
      />
      <TableFasiSchedulate
        filters={filters}
        onFilterChange={onFilterChange}
        totals={totals}
        loading={loading}
        faseConf={faseConf}
        onStatoClicked={setShowModalStato}
        fasiSchedulate={fasiSchedulate}
      />
      {fasiSchedulate !== null && !loading && (
        <Paginator
          numPages={pagination.numPages}
          currentPage={page}
          goToPage={goToPage}
        />
      )}
      <Modal isOpen={showModalStato !== null} toggle={toggleModalStato}>
        <ModalContentStato
          saving={savingStato}
          onSave={(...args) =>
            setStato.onSuccess(toggleModalStato).run(...args)
          }
          valoriStato={valoriStato}
          toggle={toggleModalStato}
          fase={showModalStato}
        />
      </Modal>
    </div>
  )
}
