import React, { useContext, useMemo, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { useRunRj, deps } from "react-rocketjump"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import FormOrder from "../../components/Planner/FormOrder"
import {
  DetailOrderState,
  FornitoriState,
  SchedulazioniOrdineLightState,
} from "./localstate"
import { ConfigPlannerContext } from "../../context"
import { fixInitialValuesNull } from "../../components/Form/Fields/utils"
import { ReadOnlyInput } from "../../components/Form/Fields"
import SchedulazioniGanttChart from "../../components/Planner/SchedulazioniGanttChart"
import ReadOnlyOrderRow from "../../components/Planner/ReadOnlyOrderRow"
import ModalFormAllegatoOrdine from "../../components/Planner/ModalFormAllegatoOrdine"
import ModalDeleteOrder from "../../components/Planner/ModalDeleteOrder"
import { ContextFornitori, ContextFornitoriActions } from "./contexts"
import { PERMISSIONS } from "../../django"
import FasiOrder from "./FasiOrder"
import ControlliOrdine from "./ControlliOrdine"
import OrdineOrdiniFornitore from "./OrdineOrdiniFornitore"
import CheckListOrdine from "../../components/Planner/CheckListOrdine"
import "./OrderDetail.css"
import OrderDetailEventsProvider, {
  useEmitOrderDetailEvents,
} from "./OrderDetailEventsProvider"
import { ImportRigheOrdiniFornitoreForm } from "./ImportRigheOrdiniFornitore"
import ModalUpdateStatoInvioMail from "../../components/Planner/ModalUpdateStatoInvioMail"
import { MessaggiOrdiniEmailsState } from "./localstate/messaggi-ordini-emails"

function mapRigheOrdineForForm(righe) {
  return righe.map((riga) => ({
    ...riga,
    codice_serramento_id: riga.codice_serramento.id,
    tipo_serramento_id: riga.tipo_serramento.id,
  }))
}

function EditOrder({
  order,
  removingSchedule,
  history,
  updateOrder,
  removeSchedulazione,
  fetchOrder,
}) {
  const { tipiSerramento, codiciSerramento } = useContext(ConfigPlannerContext)
  const [isRemoveScheduleOpen, setRemoveScheduleOpen] = useState(false)
  const toggleRemoveSchedule = useCallback(() => {
    if (!removingSchedule) {
      setRemoveScheduleOpen((a) => !a)
    }
  }, [removingSchedule])

  const orderForForm = useMemo(() => {
    if (order === null) {
      return null
    }
    const orderForForm = {
      ...order,
      righe_ordine: mapRigheOrdineForForm(order.righe_ordine),
    }
    return fixInitialValuesNull(orderForForm)
  }, [order])

  return (
    <>
      {orderForForm && (
        <FormOrder
          showLinks={false}
          toggleRemoveSchedule={toggleRemoveSchedule}
          order={orderForForm}
          fetchOrder={fetchOrder}
          tipiSerramento={tipiSerramento}
          codiciSerramento={codiciSerramento}
          history={history}
          save={(ordine) => {
            // remove extra_fields from righe_ordine
            const ordineForSave = {
              ...ordine,
              righe_ordine: ordine.righe_ordine.map((riga) => {
                const { extra_fields, ...rigaForSave } = riga
                return rigaForSave
              }),
            }
            return (
              updateOrder
                .asPromise(ordineForSave)
            )
          }}
        />
      )}
      <Modal toggle={toggleRemoveSchedule} isOpen={isRemoveScheduleOpen}>
        <ModalHeader toggle={toggleRemoveSchedule}>
          Rimuovi schedulazione
        </ModalHeader>
        {order && (
          <ModalBody>
            Rimuovere schedulazione ordine {order.numero_commessa}?
          </ModalBody>
        )}
        <ModalFooter className="justify-content-between">
          <Button disabled={removingSchedule} onClick={toggleRemoveSchedule}>
            Annulla
          </Button>
          <Button
            disabled={removingSchedule}
            color="danger"
            onClick={() => {
              removeSchedulazione
                .onSuccess(() => {
                  toggleRemoveSchedule()
                })
                .run(order)
            }}
          >
            Rimuovi
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

function OrderHead({ order, history, onDeleteClick, onOpenInvioMail }) {
  const emitOrderDetailEvent = useEmitOrderDetailEvents()
  const [modalImport, modalImportActions] = useModalTrigger()
  return (
    <div className="card mt-4 w-100">
      <div className="card-header position-relative text-center font-weight-bold">
        <div style={{ left: 10, top: 4 }} className="position-absolute">
          <Button tag={Link} size="sm" to={"/"} color="secondary" outline>
            <i className="fas fa-arrow-left"></i> Lista ordini
          </Button>
          {order && PERMISSIONS.deleteOrder && (
            <>
              <Button
                className="ml-4"
                size="sm"
                onClick={onDeleteClick}
                color="danger"
                outline
              >
                <i className="fas fa-trash"></i> Elimina
              </Button>
              {PERMISSIONS.adminOrders && (
                <>
                  <Button
                    outline
                    className="ml-2"
                    color="primary"
                    size="sm"
                    onClick={modalImportActions.toggle}
                  >
                    <i className="fa fa-upload" aria-hidden="true"></i> Importa
                    Ordini Fornitore
                  </Button>
                  <Modal
                    isOpen={modalImport.isOpen}
                    onClosed={modalImportActions.onClosed}
                    toggle={modalImportActions.toggle}
                  >
                    <ModalHeader toggle={modalImportActions.toggle}>
                      Importa Ordini Fornitore (Ordine #{order.numero_commessa})
                    </ModalHeader>
                    <ModalBody>
                      <ImportRigheOrdiniFornitoreForm
                        importOnlyOrder={order.id}
                        showResults={false}
                        onImportSuccess={(data) => {
                          emitOrderDetailEvent({
                            type: "ordiniFornitoriImported",
                            payload: data,
                          })
                          modalImportActions.close()
                        }}
                      />
                    </ModalBody>
                  </Modal>
                </>
              )}
            </>
          )}
        </div>
        <div style={{ right: 10, top: 4 }} className="position-absolute">
          {order && PERMISSIONS.adminOrders && order.schedulazione_ordine && order.cliente_data.email && (
            <>
              {order.stato_invio_mail && order.stato_invio_mail !== "" ? (
                order.stato_invio_mail === "inviata" ? (
                  <Button
                    onClick={() => onOpenInvioMail(order)}
                    size="sm"
                    disabled={!PERMISSIONS.editOrder}
                    outline
                    type="button"
                    color="primary"
                  >
                    <i className="fas fa-check"></i> Inviata
                  </Button>
                ) : (
                  <Button
                    onClick={() => onOpenInvioMail(order)}
                    size="sm"
                    disabled={!PERMISSIONS.editOrder}
                    outline
                    type="button"
                    color="primary"
                  >
                    <i className="fas fa-clock-o"></i> In attesa invio
                  </Button>
                )
              ) : (
                <Button
                  onClick={() => onOpenInvioMail(order)}
                  size="sm"
                  disabled={!PERMISSIONS.editOrder}
                  outline
                  type="button"
                  color="primary"
                >
                  <i className="fas fa-envelope"></i> Programma mail
                </Button>
              )}
            </>
          )}
          {order &&
            PERMISSIONS.scheduleOrder &&
            (!order.non_schedulabile ? (
              <Button
                tag={Link}
                size="sm"
                className="ml-2"
                to={`/schedulatore?id=${order.id}`}
                color="success"
                outline
              >
                <i className="fas fa-clock"></i>{" "}
                {order && order.schedulazione_ordine
                  ? "Schedulato"
                  : "Non schedulato"}
              </Button>
            ) : (
              <Button className="ml-2" size="sm" color="danger" outline>
                <i className="fas fa-warning"></i> {"Non schedulabile"}
              </Button>
            ))}
        </div>
        <div className="pt-1 d-none d-sm-block">
          {"Modifica ordine "}
          {order && order.numero_commessa}
        </div>
        <div className="d-block d-sm-none pt-1">&nbsp;</div>
      </div>
      {order && (
        <div className="row pl-3 pr-3 pt-2">
          <div className="col-md-2">
            <ReadOnlyInput
              label="Data commessa *"
              type="text"
              name="data"
              value={order.data}
              className="mb-2 form-control"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Data approntamento"
              name="data_consegna"
              value={order.data_consegna}
              className="mb-2 form-control"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Data posa"
              name="data_posa"
              value={order.data_posa}
              className="mb-2 form-control"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Numero commessa *"
              type="text"
              name="numero_commessa"
              value={order.numero_commessa}
              className="mb-2"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Riferimento"
              type="text"
              name="riferimento"
              value={order.riferimento}
              className="mb-2"
            />
          </div>
          {PERMISSIONS.viewAgenteOnOrders && (
            <div className="col-md-2">
              <ReadOnlyInput
                label="Agente"
                name="agente"
                value={
                  order.agente_data
                    ? `${order.agente_data.user.first_name || " "} ${
                        order.agente_data.user.last_name || " "
                      }`.trim()
                    : ""
                }
                className="mb-2"
              />
            </div>
          )}
        </div>
      )}
      {order && (
        <div className="row pl-3 pr-3 pb-2">
          <div className="col-md-2">
            <ReadOnlyInput
              label="Nome cliente *"
              type="text"
              name="nome_cliente"
              value={order.cliente_data ? order.cliente_data.nome : ""}
              className="mb-2"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Codice cliente"
              type="text"
              name="codice_cliente"
              value={order.cliente_data ? order.cliente_data.codice : ""}
              className="mb-2"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Tipo cliente"
              type="text"
              name="tipo_cliente"
              value={order.tipo_cliente}
              className="mb-2"
            />
          </div>
        </div>
      )}
      {order && (
        <div className="row pl-3 pr-3 pb-2">
          {PERMISSIONS.viewPricesOnOrders && (
            <>
              <div className="col-md-2">
                <ReadOnlyInput
                  label="Prezzo listino"
                  type="number"
                  name="listino"
                  value={order.listino}
                  className="mb-2"
                />
              </div>
              <div className="col-md-2">
                <ReadOnlyInput
                  label="Sconto"
                  type="number"
                  name="sconto"
                  value={order.sconto}
                  className="mb-2"
                />
              </div>
              <div className="col-md-2">
                <ReadOnlyInput
                  label="Netto"
                  type="number"
                  name="netto"
                  value={order.netto}
                  className="mb-2"
                />
              </div>
            </>
          )}
          <div className="col-md-2">
            <ReadOnlyInput
              label="Stato ordine"
              type="text"
              name="stato"
              value={order.stato}
              className="mb-2"
            />
          </div>
          <div className="col-md-2">
            <ReadOnlyInput
              label="Codice sistema"
              type="text"
              name="codice_sistema"
              value={order.codice_sistema}
              className="mb-2"
            />
          </div>
          <div className="col-md-2 d-flex flex-column justify-content-end">
            {PERMISSIONS.autoScheduleOrder && (
              <div className="mb-1">
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={order.auto_schedulazione}
                  disabled
                />
                Auto Schedulazione
              </div>
            )}
            <div className="mb-2">
              <input
                className="mr-2"
                type="checkbox"
                checked={order.non_schedulabile}
                disabled
              />
              Non Schedulabile
            </div>
          </div>
        </div>
      )}
      {order && (
        <div className="row pl-3 pr-3 pb-2">
          <div className="col-md-4">
            <ReadOnlyInput
              label="Note"
              type="textarea"
              name="note"
              value={order.note}
              className="mb-2"
            />
          </div>
        </div>
      )}
    </div>
  )
}

function RecapSchedulazioniOrder({ order }) {
  const [{ data: schedulazioni }] = useRunRj(SchedulazioniOrdineLightState, [
    deps.maybeGet(order, "id"),
  ])

  return (
    <div className="my-2">
      {schedulazioni && schedulazioni.length === 0 && (
        <div className="text-center mt-3">
          <i>
            <Link to={`/schedulatore?id=${order.id}`}>Schedula</Link> l'ordine
            per vedere qui il riassunto delle schedulazioni.
          </i>
        </div>
      )}
      {schedulazioni && schedulazioni.length > 0 && (
        <SchedulazioniGanttChart schedulazioni={schedulazioni} />
      )}
    </div>
  )
}

function ViewReadOnlyOrder({ order, fetchOrder }) {
  return (
    <div className="mb-2">
      {order &&
        order.righe_ordine.map((riga, index) => (
          <ReadOnlyOrderRow key={riga.id} riga={riga} index={index} fetchOrder={fetchOrder} order={order} />
        ))}
    </div>
  )
}

function AllegatiOrdine({
  order,
  addAllegato,
  removeAllegato,
  removingAllegato,
}) {
  const [isAddOpen, setIsAddOpen] = useState(false)
  const toggleAddOpen = useCallback(() => setIsAddOpen((a) => !a), [])

  const [isRemoveOpen, setRemoveOpen] = useState(null)
  const toggleRemoveOpen = useCallback(() => setRemoveOpen(null), [])

  return (
    <div className="mb-2 px-md-5 pt-3">
      {order && (
        <div>
          {PERMISSIONS.updateOrderAllegati && (
            <div className="mb-2">
              <Button onClick={toggleAddOpen} color="primary" outline>
                Aggiungi Allegato
                <i className="fa fa-paperclip ml-2" aria-hidden="true"></i>
              </Button>
            </div>
          )}
          <ul className="list-group">
            {order.allegati.length === 0 && (
              <li className="list-group-item text-center">
                <i>Nusson allegato per questo ordine</i>
              </li>
            )}
            {order.allegati.map((allegato) => (
              <li
                className="list-group-item d-flex justify-content-between"
                key={allegato.id}
              >
                <div>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={allegato.allegato}
                  >
                    {allegato.original_name}
                  </a>
                </div>
                {PERMISSIONS.updateOrderAllegati && (
                  <div
                    className="text-danger pointer"
                    onClick={() => {
                      setRemoveOpen(allegato)
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      <Modal isOpen={isRemoveOpen !== null} toggle={toggleRemoveOpen}>
        <ModalHeader toggle={toggleRemoveOpen}>Rimuovi Allegato</ModalHeader>
        <ModalBody>
          {isRemoveOpen && `Rimuovere allegato ${isRemoveOpen.original_name}?`}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <Button disabled={removingAllegato} onClick={toggleRemoveOpen}>
            Annulla
          </Button>
          <Button
            disabled={removingAllegato}
            color="danger"
            onClick={() => {
              removeAllegato
                .onSuccess(() => {
                  toggleRemoveOpen()
                })
                .run(isRemoveOpen.id)
            }}
          >
            Rimuovi
          </Button>
        </ModalFooter>
      </Modal>
      <ModalFormAllegatoOrdine
        isOpen={isAddOpen}
        toggle={toggleAddOpen}
        save={(allegato) => {
          return addAllegato
            .onSuccess(() => {
              toggleAddOpen()
            })
            .asPromise({
              ordine: order.id,
              ...allegato,
            })
        }}
      />
    </div>
  )
}

const EMPTY_LIST = []
const BIG_PAGE_SIZE = {
  page_size: 1000,
}

export default function OrderDetail({ history, match }) {
  const { id, tab = "view" } = match.params

  const changeTab = useCallback(
    (tab) => {
      history.push(`/ordini/${id}/${tab}`)
    },
    [history, id]
  )

  const [
    { data: order, removingSchedule, removingAllegato, deleting },
    {
      updateOrder,
      removeSchedulazione,
      addAllegato,
      removeAllegato,
      patchOrder,
      deleteOrder,
      writeCheckList,
      run: fetchOrder,
    },
  ] = useRunRj(DetailOrderState, [id])

  const [{ fornitori }, { run: fetchFornitori }] = useRunRj(FornitoriState, [
    BIG_PAGE_SIZE,
  ])
  const fornitoriActions = useMemo(
    () => ({
      refreshFornitori: fetchFornitori.curry(BIG_PAGE_SIZE),
    }),
    [fetchFornitori]
  )

  const [showDelete, setShowDelete] = useState(false)
  const toggleDelete = useCallback(() => setShowDelete((s) => !s), [])

  const [modalInvioMail, actionsModalInvioMail] = useModalTrigger()
  const [{ messaggi }] = useRunRj(MessaggiOrdiniEmailsState)

  return (
    <OrderDetailEventsProvider>
      <ContextFornitoriActions.Provider value={fornitoriActions}>
        <ContextFornitori.Provider value={fornitori || EMPTY_LIST}>
          <div className="container-fluid">
            <OrderHead
              order={order}
              history={history}
              onDeleteClick={() => setShowDelete(true)}
              onOpenInvioMail={actionsModalInvioMail.open}
            />
            <Nav tabs className="mt-1">
              {PERMISSIONS.editOrder && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "edit" })}
                    onClick={() => changeTab("edit")}
                  >
                    Modifica
                  </NavLink>
                </NavItem>
              )}
              {PERMISSIONS.viewOrderFasi && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "fasi" })}
                    onClick={() => changeTab("fasi")}
                  >
                    Fasi
                  </NavLink>
                </NavItem>
              )}
              {PERMISSIONS.viewOrderFornitore && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "fornitori" })}
                    onClick={() => changeTab("fornitori")}
                  >
                    Ordini a Fornitore
                  </NavLink>
                </NavItem>
              )}
              {PERMISSIONS.scheduleOrder && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "schedulazioni" })}
                    onClick={() => changeTab("schedulazioni")}
                  >
                    Recap. Schedulazioni
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classNames({ active: tab === "view" })}
                  onClick={() => changeTab("view")}
                >
                  Dettaglio
                </NavLink>
              </NavItem>
              {PERMISSIONS.viewOrderAllegati && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "allegati" })}
                    onClick={() => changeTab("allegati")}
                  >
                    Allegati
                  </NavLink>
                </NavItem>
              )}
              {(PERMISSIONS.viewOrderControlli ||
                PERMISSIONS.viewMarcaturaCe) && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "marcatura-ce" })}
                    onClick={() => changeTab("marcatura-ce")}
                  >
                    Marcatura CE
                  </NavLink>
                </NavItem>
              )}
              {(PERMISSIONS.writeOrderCheckList ||
                PERMISSIONS.viewMarcaturaCe) && (
                <NavItem>
                  <NavLink
                    className={classNames({ active: tab === "checklist" })}
                    onClick={() => changeTab("checklist")}
                  >
                    Checklist
                  </NavLink>
                </NavItem>
              )}
              {PERMISSIONS.canViewLineaSaomad && (
              <NavItem>
                <NavLink
                  className={classNames({ active: tab === "logs" })}
                  onClick={() => changeTab("logs")}
                >
                  Linea Saomand Logs
                </NavLink>
              </NavItem>
            )}
            </Nav>

            {tab === "edit" && PERMISSIONS.editOrder && (
              <EditOrder
                history={history}
                order={order}
                fetchOrder={fetchOrder}
                removingSchedule={removingSchedule}
                updateOrder={updateOrder}
                removeSchedulazione={removeSchedulazione}
              />
            )}

            {tab === "fasi" && PERMISSIONS.viewOrderFasi && (
              <FasiOrder order={order} />
            )}

            {tab === "schedulazioni" && PERMISSIONS.scheduleOrder && (
              <RecapSchedulazioniOrder order={order} />
            )}

            {tab === "fornitori" && <OrdineOrdiniFornitore order={order} />}

            {tab === "view" && <ViewReadOnlyOrder order={order} fetchOrder={fetchOrder} />}

            {tab === "allegati" && (
              <AllegatiOrdine
                removeAllegato={removeAllegato}
                removingAllegato={removingAllegato}
                order={order}
                addAllegato={addAllegato}
              />
            )}

            {tab === "marcatura-ce" && <ControlliOrdine order={order} />}
            {tab === "checklist" && order && (
              <CheckListOrdine writeCheckList={writeCheckList} order={order} />
            )}
            {tab === 'logs' && PERMISSIONS.canViewLineaSaomad && (
              <div className="p-2">
                <pre>
                  {order?.log_saomad || 'Nessun log disponibile'}
                </pre>
              </div>
            )}
            <ModalDeleteOrder
              toggle={toggleDelete}
              isOpen={showDelete}
              ordine={order}
              deleting={deleting}
              onSetEliminato={() => {
                deleteOrder
                  .onSuccess(() => {
                    history.push(`/ordini`)
                  })
                  .run(order.id)
              }}
            />
            <ModalUpdateStatoInvioMail
              ordine={modalInvioMail.value}
              isOpen={modalInvioMail.isOpen}
              toggle={actionsModalInvioMail.toggle}
              messaggi={messaggi || []}
              save={(ordine) => {
                return patchOrder
                  .onSuccess(() => {
                    actionsModalInvioMail.close()
                    // NOTE: can be improved
                  })
                  .asPromise({
                    id: id,
                    testo_email: ordine.testo_email,
                    stato_invio_mail: ordine.stato_invio_mail,
                  })
              }}
              onClosed={actionsModalInvioMail.onClosed}
            />
          </div>
        </ContextFornitori.Provider>
      </ContextFornitoriActions.Provider>
    </OrderDetailEventsProvider>
  )
}
