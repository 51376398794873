import React from 'react'
import useMemoCompare from 'magik-react-hooks/useMemoCompare'
import { useRunRj } from 'react-rocketjump'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { OrdineOrdiniFornitoreState } from '../../../apps/PlannerApp/localstate'
import './ModalOrdiniFornitori.css'
import moment from 'moment'

function TableOrdiniForitore({ orderId, tipologia }) {
  const [{ list }] = useRunRj(OrdineOrdiniFornitoreState, [
    orderId,
    useMemoCompare({
      tipologia,
    }),
  ])

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Fornitore</th>
          <th>Stato</th>
          <th>Data Inserimento</th>
          <th>Data Consegna</th>
          <th>PDF</th>
          <th>Allegati</th>
          <th>Note</th>
        </tr>
      </thead>
      <tbody>
        {list &&
          list.map((ordineFornitore) => (
            <tr key={ordineFornitore.id}>
              <td>{ordineFornitore.fornitore_data.nome}</td>
              <td>{ordineFornitore.stato}</td>
              <td>
                {moment(ordineFornitore.data_inserimento).format('YYYY-MM-DD')}
              </td>
              <td>{ordineFornitore.data_consegna}</td>
              <td className="text-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  href={
                    ordineFornitore.email_pdf ??
                    `/api/planner/ordini-fornitore/${ordineFornitore.id}/pdf/`
                  }
                >
                  <i className="fas fa-file-pdf-o mr-2 text-danger" />
                </a>
              </td>
              <td>
                <ul>
                  {ordineFornitore.allegati_data.map((allegato) => (
                    <li key={allegato.id} className="fixed-list-item">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        title={allegato.original_name}
                        href={allegato.allegato}
                      >
                        {allegato.original_name}
                      </a>
                    </li>
                  ))}
                </ul>
              </td>
              <td>{ordineFornitore.note}</td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

export default function ModalOrdiniFornitori({
  tipologia,
  toggle,
  isOpen,
  onClosed,
  orderId,
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      size="xl"
      className="ModalOrdiniFornitori"
    >
      <ModalHeader toggle={toggle}>
        Ordini Fornitore <span className="text-capitalize">{tipologia}</span>
      </ModalHeader>
      <ModalBody>
        {orderId && (
          <TableOrdiniForitore tipologia={tipologia} orderId={orderId} />
        )}
      </ModalBody>
    </Modal>
  )
}
