import React, { useState, useCallback, useMemo, useContext } from 'react'
import { Button } from 'reactstrap'
import { getColorByOrderFornitoreId } from '../../apps/PlannerApp/utils'
import { ContextOrdineOrdiniFornitoriActions } from '../../apps/PlannerApp/contexts'
import CircularProgress from '@material-ui/core/CircularProgress'
import dayjs from 'dayjs'
import find from 'lodash/find'

const Card = ({
  ordineFornitore,
  tipologiaSelected,
  sending,
  fasiOrdine,
  checked,
}) => {
  const color = getColorByOrderFornitoreId(ordineFornitore.id)
  const [cardActive, setCard] = useState(false)
  const toggleCard = useCallback(() => {
    setCard((show) => !show)
  }, [])

  const schedulazione = useMemo(() => {
    if (!fasiOrdine) {
      return null
    }
    const fase = find(
      fasiOrdine,
      (fase) =>
        fase.nome_fase === ordineFornitore.tipologia &&
        fase.schedulazione?.fornitore?.id === ordineFornitore.fornitore
    )
    if (!fase) {
      return null
    }
    return fase.schedulazione
  }, [fasiOrdine, ordineFornitore])

  const {
    moveRighe,
    toggleShowModalFornitore,
    toggleSelectedOrdineFornitore,
    filterOrdineFornitore,
    openMailModalOrdineFornitore,
    openUpdateModalOrdineFornitore,
    openDeleteModalOrdineFornitore,
    openMoveSchedulazioneModal,
  } = useContext(ContextOrdineOrdiniFornitoriActions)

  return (
    <div
      className="card card-ordini-fornitori mt-2"
      style={{ borderColor: color, borderWidth: '3px' }}
    >
      <div
        onClick={() => {
          toggleCard()
        }}
        className="card-header pointer font-weight-bold"
      >
        <div className="text-capitalize pointer">
          {ordineFornitore.tipologia} -{' '}
          <small>{ordineFornitore.fornitore_data.nome}</small>
          {ordineFornitore.stato && (
            <div>
              <small>Stato: {ordineFornitore.stato}</small>
            </div>
          )}
          {schedulazione && (
            <div>
              <small>
                Data schedulazione:{' '}
                <strong>
                  {dayjs(schedulazione.fine).format('DD-MM-YYYY')}
                </strong>
                <Button size='sm'  color='dark' className='ml-2' outline onClick={e => {
                  e.stopPropagation()
                  openMoveSchedulazioneModal(schedulazione)
                }}>
                  <i className="fas fa-calendar text-success" />
                </Button>
              </small>
            </div>
          )}
          {ordineFornitore.data_consegna && (
            <div>
              <small>
                Data consegna:{' '}
                <strong>
                  {dayjs(ordineFornitore.data_consegna).format('DD-MM-YYYY')}
                </strong>
              </small>
            </div>
          )}{' '}
          <div>
            <small>Numero righe: {ordineFornitore.righe_count}</small>
          </div>
        </div>
        <div className="ordini-fonitori-cards text-left mt-2">
          <Button
            onClick={(e) => {
              e.stopPropagation()
              openUpdateModalOrdineFornitore(ordineFornitore)
            }}
            size="sm"
            className="ml-1"
            outline
            color="dark"
          >
            <i className="fas fa-pencil-alt text-warning" /> Modifica
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              openDeleteModalOrdineFornitore(ordineFornitore)
            }}
            size="sm"
            outline
            className="ml-2"
            color="dark"
          >
            <i className="fas fa-trash text-danger" /> Elimina
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              filterOrdineFornitore(ordineFornitore)
            }}
            size="sm"
            outline
            className="ml-2"
            color="dark"
          >
            <i className="fas fa-filter text-primary" /> Filtra
          </Button>
          {(!ordineFornitore.stato || ordineFornitore.stato === 'DA_FARE') &&
            ordineFornitore.fornitore_data.email &&
            ordineFornitore.righe_count > 0 && (
              <Button
                disabled={sending}
                onClick={(e) => {
                  e.stopPropagation()
                  openMailModalOrdineFornitore(ordineFornitore)
                }}
                size="sm"
                className="ml-2"
                outline
                color="dark"
              >
                <i className="fas fa-envelope text-warning" /> Invia mail
              </Button>
            )}
          {ordineFornitore.email_sent_at && (
            <Button className="ml-2" disabled size="sm" outline color="dark">
              <i className="fas fa-envelope text-warning" />{' '}
              {dayjs(ordineFornitore.email_sent_at).format('DD-MM-YYYY')}
            </Button>
          )}
          {ordineFornitore.righe_count > 0 && !ordineFornitore.email_pdf && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation()
              }}
              href={`/api/planner/ordini-fornitore/${ordineFornitore.id}/pdf/`}
              className="btn btn-sm btn-outline-dark ml-2"
            >
              <i className="fas fa-file-pdf-o mr-2 text-danger" /> Pdf
            </a>
          )}
          {ordineFornitore.email_pdf && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation()
              }}
              href={ordineFornitore.email_pdf}
              className="btn btn-sm btn-outline-dark ml-2"
            >
              <i className="fas fa-file-pdf-o mr-2 text-danger" /> Pdf
            </a>
          )}
          {tipologiaSelected === ordineFornitore.tipologia && (
            <Button
              onClick={(e) => {
                e.stopPropagation()
                moveRighe(ordineFornitore)
              }}
              size="sm"
              style={{ top: 10, right: 50 }}
              className="ml-2 position-absolute"
              color="success"
            >
              <i className="fas fa-arrows-alt text-white" /> Sposta
            </Button>
          )}
          <div
            className="ml-2 position-absolute"
            style={{ top: 10, right: 10 }}
          >
            <input
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                toggleSelectedOrdineFornitore(ordineFornitore.id)
              }}
              checked={checked}
              type="checkbox"
            />
          </div>
        </div>
      </div>
      {cardActive && (
        <div className="card-body">
          <div>
            Fornitore:{' '}
            <strong
              className="pointer"
              onClick={() => {
                toggleShowModalFornitore(ordineFornitore.fornitore_data)
              }}
            >
              {ordineFornitore.fornitore_data.email ? (
                ordineFornitore.fornitore_data.nome
              ) : (
                <span className="text-red">
                  {ordineFornitore.fornitore_data.nome}
                </span>
              )}{' '}
              {ordineFornitore.fornitore_data.codice && (
                <span>({ordineFornitore.fornitore_data.codice})</span>
              )}
              <i
                title={'Modifica fornitore'}
                className="fas ml-2 fa-pencil-alt text-warning"
              />
            </strong>
            {ordineFornitore.fornitore_data.email && (
              <div>
                <small>
                  <i className="fas fa-envelope" />{' '}
                  {ordineFornitore.fornitore_data.email}
                </small>
              </div>
            )}
          </div>
          {ordineFornitore.allegati_data.length > 0 && (
            <div>
              Allegati:{' '}
              <ul>
                {ordineFornitore.allegati_data.map((allegato) => (
                  <li key={allegato.id}>
                    <a href={allegato.allegato}>
                      {allegato.original_name || 'Allegato'}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {ordineFornitore.note && <div>Note: {ordineFornitore.note}</div>}
          {ordineFornitore.storico_stati.length > 0 && (
            <table className="table table-bordered table-striped mt-2">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Stato</th>
                </tr>
              </thead>
              <tbody>
                {ordineFornitore.storico_stati.map((storico, key) => (
                  <tr key={key}>
                    <td>
                      {dayjs(storico.changed_at).format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td>{storico.stato}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  )
}

export default function OrdiniFornitoreCardList({
  ordiniFornitore,
  tipologiaSelected,
  loading,
  sending,
  fasiOrdine,
  selectedOrdiniFornitoriIds,
}) {
  return (
    <div className="OrdiniFornitoriCardList">
      {ordiniFornitore === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {ordiniFornitore &&
        ordiniFornitore.map((ordineFornitore, key) => (
          <Card
            key={key}
            sending={sending}
            checked={!!selectedOrdiniFornitoriIds[ordineFornitore.id]}
            fasiOrdine={fasiOrdine}
            tipologiaSelected={tipologiaSelected}
            ordineFornitore={ordineFornitore}
          />
        ))}
    </div>
  )
}
