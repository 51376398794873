import React, { useContext, useState, useCallback, useMemo } from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import { FornitoriState, OrdiniFornitoreState } from './localstate'
import Paginator from '../../components/Paginator'
import ModalUpdateStato from '../../components/Planner/ModalUpdateStato'
import DateRangeQsFilter from '../../components/Planner/DateRangeQsFilter'
import Column from '../../components/Column'
import { ConfigPlannerContext } from '../../context'
import { PERMISSIONS } from '../../django'
import { Modal, ModalHeader } from 'reactstrap'
import OrderFornitoreForm from '../../components/Planner/OrderFornitoreForm'
import { getFasiNeededByOrder } from './localstate/common'
import { ContextFornitori, ContextFornitoriActions } from './contexts'
import { useRunRj } from 'react-rocketjump'
import moment from 'moment'
import { CircularProgress } from '@material-ui/core'
import MultipleMaterialiFilters from '../../components/Planner/MultipleMaterialiFilters'

const qpList = {
  encode: (a) => a,
  decode: (a) => (Array.isArray(a) ? a : [a]).filter(Boolean),
}

const EMPTY_LIST = []

const BIG_PAGE_SIZE = {
  page_size: 1000,
}

const OrdineFornitoreRow = React.memo(
  ({ ordineFornitore, onStatoClicked, openUpdateModalOrdineFornitore }) => (
    <tr>
      <td>
        {PERMISSIONS.viewOrders && ordineFornitore.ordine_data ? (
          <Link to={`/ordini/${ordineFornitore.ordine_data.id}/fasi`}>
            {ordineFornitore.ordine_data.numero_commessa}
          </Link>
        ) : ordineFornitore.ordine ? (
          <span>{ordineFornitore.ordine_data.numero_commessa}</span>
        ) : (
          <span>
            <i className="fa fa-times text-danger" />
          </span>
        )}
      </td>
      <td>
        {ordineFornitore.ordine_data ? (
          ordineFornitore.ordine_data.riferimento
        ) : (
          <span>
            <i className="fa fa-times text-danger" />
          </span>
        )}
      </td>
      <td>
        {ordineFornitore.ordine_data && ordineFornitore.ordine_data.cliente ? (
          ordineFornitore.ordine_data.cliente.nome
        ) : (
          <span>
            <i className="fa fa-times text-danger" />
          </span>
        )}
      </td>
      <td>{moment(ordineFornitore.data_inserimento).format('YYYY-MM-DD')}</td>
      <td>
        {ordineFornitore.ordine_data ? (
          ordineFornitore.ordine_data.data_consegna
        ) : (
          <span>
            <i className="fa fa-times text-danger" />
          </span>
        )}
      </td>
      <td>{ordineFornitore.data_consegna}</td>
      <td>
        <span>{ordineFornitore.fornitore_data.nome}</span>
      </td>
      <td className="text-capitalize">{ordineFornitore.tipologia}</td>
      <td>
        <div className="d-flex align-items-center">
          {ordineFornitore.stato}
          <Button
            onClick={() => onStatoClicked(ordineFornitore)}
            color="success"
            outline
            size="sm"
            className="ml-1"
          >
            <i className={`fas fa-edit`}></i>
          </Button>
        </div>
      </td>
      <td className="text-center">
        {ordineFornitore.ordine && (
          <>
            {!ordineFornitore.email_pdf ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation()
                }}
                href={`/api/planner/ordini-fornitore/${ordineFornitore.id}/pdf/`}
              >
                <i className="fas fa-file-pdf-o mr-2 text-danger" />
              </a>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation()
                }}
                href={ordineFornitore.email_pdf}
              >
                <i className="fas fa-file-pdf-o mr-2 text-danger" />
              </a>
            )}
          </>
        )}
      </td>
      <td>
        <ul>
          {ordineFornitore.allegati_data.map((allegato) => (
            <li key={allegato.id}>
              <a href={allegato.allegato}>
                {allegato.original_name || 'Allegato'}
              </a>
            </li>
          ))}
        </ul>
      </td>
      <td>{ordineFornitore.note}</td>
      <td>
        <div
          onClick={() => {
            openUpdateModalOrdineFornitore(ordineFornitore)
          }}
          className="pointer"
        >
          <i
            title="Modifica ordine fornitore"
            className="fas fa-pencil-alt text-warning"
          />
        </div>
      </td>
    </tr>
  )
)

const FilterRow = ({
  filters,
  onFilterChange,
  onFiltersChange,
  onFilterChangeDebounced,
}) => {
  const { materiali, valoriStato } = useContext(ConfigPlannerContext)
  const statiOrdine = valoriStato.ordine_fornitore || EMPTY_LIST
  return (
    <tr>
      <th>
        <input
          className="form-control"
          autoComplete="off"
          type="text"
          name="numero_commessa"
          value={filters.numero_commessa}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th>
        <input
          className="form-control"
          type="text"
          name="riferimento"
          value={filters.riferimento}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th>
        <input
          autoComplete="off"
          className="form-control"
          type="text"
          name="cliente"
          value={filters.cliente}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th>
        <DateRangeQsFilter
          filters={filters}
          onFiltersChange={onFiltersChange}
          startField="from_data_inserimento"
          endField="to_data_inserimento"
          withPortal={true}
          customArrowIcon={<div />}
          small={false}
          noBorder
        />
      </th>
      <th>
        <DateRangeQsFilter
          filters={filters}
          onFiltersChange={onFiltersChange}
          startField="from_data_consegna"
          endField="to_data_consegna"
          withPortal={true}
          customArrowIcon={<div />}
          small={false}
          noBorder
        />
      </th>
      <th>
        <DateRangeQsFilter
          filters={filters}
          onFiltersChange={onFiltersChange}
          startField="from_data_consegna_fornitore"
          endField="to_data_consegna_fornitore"
          withPortal={true}
          customArrowIcon={<div />}
          small={false}
          noBorder
        />
      </th>
      <th>
        <input
          className="form-control"
          type="text"
          name="fornitore_nome"
          value={filters.fornitore_nome}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th>
        <select
          value={filters.tipologia}
          onChange={onFilterChange}
          name="tipologia"
          className="form-control"
        >
          <option value=""></option>
          <option value="vetro">Vetro</option>
          <option value="legno">Legno</option>
          <option value="verniciatura">Verniciatura</option>
          <option value="telai">Telai</option>
          {materiali.map((materiale) => (
            <option key={materiale} value={materiale}>
              {materiale}
            </option>
          ))}
        </select>
      </th>
      <th>
        <select
          value={filters.stato}
          onChange={onFilterChange}
          name="stato"
          className="form-control"
        >
          <option value=""></option>
          {statiOrdine.map((stato) => (
            <option value={stato.nome} key={stato.nome}>
              {stato.nome}
            </option>
          ))}
        </select>
      </th>
      <th></th>
      <th></th>
      <th>
        <input
          className="form-control"
          type="text"
          name="note"
          value={filters.note}
          onChange={onFilterChangeDebounced}
        />
      </th>
      <th></th>
    </tr>
  )
}

export default function OrdiniFornitore() {
  const [
    { list: ordiniFornitore, pagination, updatingStato, loading },
    { page, ...filters },
    {
      onFilterChange,
      onFiltersChange,
      onFilterChangeDebounced,
      goToPage,
      updateStato,
      updateOrdineFornitore,
      addOrdineFornitoreWithoutInsert,
      refresh,
    },
  ] = useDataTable(OrdiniFornitoreState, () => ({
    fornitore_nome: '',
    tipologia: '',
    stato: '',
    note: '',
    cliente: '',
    from_data_consegna: '',
    to_data_consegna: '',
    from_data_consegna_fornitore: '',
    to_data_consegna_fornitore: '',
    from_data_inserimento: '',
    to_data_inserimento: '',
    ordering: '',
    riferimento: '',
    numero_commessa: '',
    materiali: {
      value: [],
      encdec: qpList,
    },
    stati_materiali: {
      value: [],
      encdec: qpList,
    },
  }))

  const columnProps = {
    value: filters.ordering,
    onChange: (value) => onFilterChange(value, 'ordering'),
  }

  const { valoriStato, materiali, fasiScheduler } =
    useContext(ConfigPlannerContext)

  const statiOrdineFornitore = valoriStato.ordine_fornitore || EMPTY_LIST

  const [{ fornitori }, { run: fetchFornitori }] = useRunRj(FornitoriState, [
    BIG_PAGE_SIZE,
  ])
  const fornitoriActions = useMemo(
    () => ({
      refreshFornitori: fetchFornitori.curry(BIG_PAGE_SIZE),
    }),
    [fetchFornitori]
  )

  const tipologie = useMemo(() => {
    const fasiNeeded = getFasiNeededByOrder(null, fasiScheduler)

    const listTipologieFromFasi = fasiScheduler.reduce((list, fase) => {
      if (fase.with_fornitore && fasiNeeded[fase.nome_fase]) {
        list.push(fase.nome_fase)
      }
      return list
    }, [])
    return listTipologieFromFasi.concat(materiali)
  }, [fasiScheduler, materiali])

  const [modalStato, setModalStato] = useState(null)
  const toggleModalStato = useCallback(() => setModalStato(null), [])

  const [modalOrderFornitore, setModalOrderFornitore] = useState({
    operation: null,
    record: null,
  })
  const closeModalOrderFornitore = useCallback(() => {
    setModalOrderFornitore({ operation: null, record: null })
  }, [])
  const openCreateModalOrdineFornitore = useCallback(() => {
    setModalOrderFornitore({ operation: 'CREATE', record: null })
  }, [])
  const openUpdateModalOrdineFornitore = useCallback((record) => {
    setModalOrderFornitore({ operation: 'UPDATE', record })
  }, [])

  const coreMateriali = useMemo(
    () => fasiScheduler.filter((f) => f.with_fornitore).map((f) => f.nome_fase),
    [fasiScheduler]
  )

  const materialiOptions = useMemo(
    () => coreMateriali.concat(materiali),
    [coreMateriali, materiali]
  )

  const makeLabelMateriale = useCallback((m) => m.toUpperCase(), [])

  const valoriStatoOptionsMateriali = useMemo(() => {
    return valoriStato.ordine_fornitore.map((c) => ({
      value: c.nome,
      label: c.nome,
    }))
  }, [valoriStato])

  return (
    <div className="container-fluid">
      <div className="d-flex">
        <div className="flex-1">
          <MultipleMaterialiFilters
            makeLabelMateriale={makeLabelMateriale}
            placeholderMateriale={'-- Tipologia --'}
            filters={filters}
            valoriStatoOptions={valoriStatoOptionsMateriali}
            onFiltersChange={onFiltersChange}
            materialiOptions={materialiOptions}
          />
        </div>
        <div className="text-right">
          <Button
            onClick={() => {
              openCreateModalOrdineFornitore()
            }}
            size="sm"
            className="mt-2"
            outline
            color="secondary"
          >
            <i className="fas fa-plus-circle"></i> Aggiungi ordine fornitore
          </Button>
        </div>
      </div>
      {ordiniFornitore === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {ordiniFornitore && (
        <>
          <div className="mt-2">
            <table className="table table-bordered">
              <thead>
                <FilterRow
                  filters={filters}
                  onFilterChange={onFilterChange}
                  onFiltersChange={onFiltersChange}
                  onFilterChangeDebounced={onFilterChangeDebounced}
                />
                <tr>
                  <Column {...columnProps} field="ordine__numero_commessa">
                    Ordine
                  </Column>
                  <Column {...columnProps} field="ordine__riferimento">
                    Ordine Riferimento
                  </Column>
                  <Column {...columnProps} field="ordine__cliente__nome">
                    Cliente
                  </Column>
                  <Column {...columnProps} field="data_inserimento">
                    Data Inserimento
                  </Column>
                  <Column {...columnProps} field="ordine__data_consegna">
                    Data Approntamento
                  </Column>
                  <Column {...columnProps} field="data_consegna">
                    Data Consegna
                  </Column>
                  <Column {...columnProps} field="fornitore__nome">
                    Fornitore
                  </Column>
                  <Column {...columnProps} field="tipologia">
                    Tipologia
                  </Column>
                  <Column {...columnProps} field="stato">
                    Stato
                  </Column>
                  <th>PDF</th>
                  <th>Allegati</th>
                  <th>Note</th>
                  <th>Azioni</th>
                </tr>
              </thead>
              <tbody>
                {ordiniFornitore.map((ordineFornitore) => (
                  <OrdineFornitoreRow
                    openUpdateModalOrdineFornitore={
                      openUpdateModalOrdineFornitore
                    }
                    onStatoClicked={setModalStato}
                    key={ordineFornitore.id}
                    ordineFornitore={ordineFornitore}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <Paginator
            numPages={pagination.numPages}
            currentPage={page}
            goToPage={goToPage}
          />
        </>
      )}
      <ModalUpdateStato
        title={
          modalStato && (
            <span>
              Modifica stato ordine fornitore{' '}
              <span className="text-capitalize">{modalStato.tipologia}</span>{' '}
              <span>{modalStato.fornitore_data.nome}</span>
              {modalStato.ordine
                ? ` x Ordine ${modalStato.ordine_data.numero_commessa}`
                : ''}
            </span>
          )
        }
        initialStato={modalStato && modalStato.stato}
        required
        toggle={toggleModalStato}
        isOpen={modalStato !== null}
        saving={updatingStato}
        stati={statiOrdineFornitore}
        onSave={(stato) => {
          updateStato
            .onSuccess(() => {
              refresh()
              toggleModalStato()
            })
            .run(modalStato, stato)
        }}
      />
      <ContextFornitoriActions.Provider value={fornitoriActions}>
        <ContextFornitori.Provider value={fornitori || EMPTY_LIST}>
          <Modal
            size="lg"
            toggle={closeModalOrderFornitore}
            isOpen={
              modalOrderFornitore.operation !== null &&
              (modalOrderFornitore.operation === 'UPDATE' ||
                modalOrderFornitore.operation === 'CREATE')
            }
          >
            <ModalHeader toggle={closeModalOrderFornitore}>
              {modalOrderFornitore &&
                modalOrderFornitore.operation === 'UPDATE' &&
                'Modifica Ordine Fornitore'}
              {modalOrderFornitore &&
                modalOrderFornitore.operation === 'CREATE' &&
                'Aggiungi Ordine Fornitore'}
            </ModalHeader>
            <OrderFornitoreForm
              tipologie={tipologie}
              showOrder
              save={(data) => {
                if (modalOrderFornitore.record) {
                  return updateOrdineFornitore
                    .onSuccess(() => {
                      closeModalOrderFornitore()
                    })
                    .asPromise(data)
                } else {
                  return addOrdineFornitoreWithoutInsert
                    .onSuccess(() => {
                      refresh()
                      closeModalOrderFornitore()
                    })
                    .asPromise(data)
                }
              }}
              toggle={closeModalOrderFornitore}
              orderFornitore={modalOrderFornitore.record}
              inModal
            />
          </Modal>
        </ContextFornitori.Provider>
      </ContextFornitoriActions.Provider>
    </div>
  )
}
